import React, { useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import FormData from 'form-data';

function Camera() {
  const [deviceId, setDeviceId] = useState();
  const [text, setText] = useState('');
  const [devices, setDevices] = useState([]);
  const webcamRef = React.useRef(null);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const takePicture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Envía la imagen a la API de Vision AI
    console.log('click!')
    
    sendImageToVisionAPI(imageSrc);
  }, [webcamRef]);

  const sendImageToVisionAPI = async (imageSrc) => {
    try {
      
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const formData = new FormData();
      formData.append('file', blob, 'image.png');

      await axios.post('http://localhost:3001/api/read-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      // Extrae el texto de la respuesta de la API
      
      // setText(extractedText);
    } catch (error) {
      console.error('Error sending image to Vision AI:', error);
    }
  };

  return (
    <div>
      {/* <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={{ deviceId: deviceId }}
      /> */}
      hola mundo
      {/* <button onClick={takePicture}>Take Picture!</button>
      {devices.map((device, key) => (
        <button key={key} onClick={() => setDeviceId(device.deviceId)}>
          {device.label || `Camera ${key + 1}`}
        </button>
      ))} */}
    </div>
  );
}

export default Camera;
