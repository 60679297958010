import React from 'react';

const Home = () => {
  return (
    <div style={{
      display: 'flex',
      height: '100vh',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ff6600',
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    }}>
      <div className="container">
        <img
          src="https://s3.amazonaws.com/polloskiko.com/LOGO-POLLOS-KIKO.png"
          alt="pollos kiko logo"
          style={{ display: 'block', margin: '30px auto' }}
        />
        <h1 style={{
          color: '#fff',
          fontWeight: '60px',
          textAlign: 'center',
          fontFamily: 'Verdana, sans-serif',
        }}>Muy pronto</h1>
      </div>
    </div>
  );
};

export default Home;
